<template>
  <page>
    <page-section
      full-width
      bottom-border
    >
      <page-title>
        <div class="items-center">
          <div>Ad Generator</div>
          <div class="text-sm font-normal text-gray-700">
            Effortlessly create optimized headlines and descriptions for ads with maximum effectiveness.
          </div>
        </div>
      </page-title>
    </page-section>

    <!--MAIN CONTAINER-->
    <div
      id="adGenerator"
      class="main-container base-scrollbar"
    >
      <div
        v-if="!isPageLoading"
        class="h-100 bg-white flex"
      >
        <div class="px-6 pb-4 h-full mt-4">
          <div class="my-4">
            <div class="text-base font-bold">
              Select Offering
              <span class="text-neutral-950 font-normal">(optional)</span>
            </div>
          </div>
          <div>
            <search-input
              ref="selectOfferingField"
              class="w-500"
              input-id="search-offering"
              input-name="search-offering"
              placeholder="Search an offering..."
              options-max-width="max-w-64"
              truncate-width="w-96"
              :options="offeringOptions"
              :disabled="isAdLoading"
              @select-item="selectOffering"
            />
          </div>
          <div class="my-4">
            <div class="text-base font-bold">Select Language</div>
          </div>
          <div>
            <search-input
              ref="selectLanguageField"
              v-model="siteLanguage"
              class="w-500"
              input-id="language"
              input-name="language"
              placeholder="Search a language..."
              options-max-width="max-w-64"
              truncate-width="w-96"
              :options="allLanguages"
              :disabled="isAdLoading"
              @select-item="selectLanguage"
            />
          </div>
          <div class="mt-4">
            <div class="text-base font-bold mb-2">Generate Ad copy</div>
            <div class="text-gray-700 text-sm font-normal">
              Select number how headlines and descriptions you want to be generated
            </div>

            <div class="flex items-center gap-10 mt-6">
              <div class="text-gray-700 text-sm font-medium">Headlines</div>
              <NumericInput
                v-model="requestBody.headlines"
                :min="5"
                :max="20"
                :is-disabled="true"
              />
            </div>
            <div class="flex items-center gap-6 mt-6">
              <div class="text-gray-700 text-sm font-medium">Descriptions</div>
              <NumericInput
                v-model="requestBody.descriptions"
                :min="5"
                :max="20"
                :is-disabled="true"
              />
            </div>
            <div class="mt-6 flex">
              <div class="text-gray-700 text-sm font-medium">
                Customize input words
                <span class="text-gray-700 text-sm font-normal"> (Optional) </span>
              </div>
            </div>
            <div class="mt-2 text-gray-500 text-sm font-normal">
              Enter up to 5 words, the AI will generate suggestions that incorporate these <br />
              keywords. Please press enter to separate keywords.
            </div>
            <div>
              <vue-tags-input
                v-model="word"
                :tags="words"
                :max-tags="5"
                :disabled="isAdLoading"
                :placeholder="'eg. add the word “utbildning” to only get suggestions including “utbildning”'"
                @tags-changed="newTags => (words = newTags)"
              />

              <div class="flex space-x-4 justify-end py-8">
                <!-- <MergeButtonRound
                :button-type="'tertiary'"
                class="text-gray-600"
                @click="previous"
              >
                <span class="text-black"> Previous </span>
              </MergeButtonRound> -->
                <MergeButtonRound
                  :disabled="isAdLoading"
                  @click="generate"
                >
                  <div class="flex my-auto align-center">
                    <IcStars
                      :size="16"
                      class="my-auto mr-2"
                    />
                    <div>Generate</div>
                  </div>
                </MergeButtonRound>
              </div>
            </div>
          </div>
        </div>
        <!-- result section -->

        <div
          v-if="isAdLoading || resultExist"
          class="resultSection w-2/5 ml-auto"
        >
          <div
            v-if="isAdLoading"
            class="mt-40"
          >
            <brightbid-loader size="80" />
          </div>
          <div v-if="!isAdLoading && resultExist">
            <div class="flex items-center">
              <div>
                <img
                  class="robotIcon"
                  src="/content/images/brightbid/welcome-bot.svg"
                  alt="welcome-robot"
                />
              </div>
              <div class="my-auto ml-3 text-neutral-950 text-base font-medium">
                Here's a list of fresh headlines and <br />
                descriptions for you to copy into your Ad!
              </div>
            </div>
            <div>
              <div class="text-left text-gray-700 text-base font-medium mt-5 flex items-center">
                Headlines
                <div class="text-indigo-500 text-sm font-bold ml-4">{{ result.headlines?.length }}</div>
              </div>
              <div
                class="headlineSection bg-white rounded-lg mt-3 border w-full border-gray-200 flex-col justify-start items-start inline-flex"
              >
                <div
                  v-for="(headline, index) in result.headlines"
                  :key="headline.text"
                  class="w-full py-2 pr-2 justify-start items-center gap-4 inline-flex"
                >
                  <div class="justify-end items-center flex">
                    <div class="w-6 text-right text-gray-700 text-sm font-normal">{{ index + 1 }}</div>
                  </div>
                  <div class="text-gray-700 text-sm font-normal">{{ headline.text }}</div>
                  <div class="justify-end items-right gap-8 flex ml-auto">
                    <div
                      class="flex items-center link-primary no-underline ml-4"
                      @click="copyText(headline)"
                    >
                      <component
                        :is="headline.icon"
                        class="mr-2 grayColor"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- descriptions -->
            <div class="pb-10">
              <div class="text-left text-gray-700 text-base font-medium mt-5 flex items-center">
                Descriptions
                <div class="text-indigo-500 text-sm font-bold ml-4">{{ result.descriptions?.length }}</div>
              </div>
              <div
                class="headlineSection bg-white rounded-lg mt-3 border w-full border-gray-200 flex-col justify-start items-start inline-flex"
              >
                <div
                  v-for="(description, index) in result.descriptions"
                  :key="description.text"
                  class="w-full py-2 pr-2 justify-start items-center gap-4 inline-flex"
                >
                  <div class="justify-end items-center flex">
                    <div class="w-6 text-right text-gray-700 text-sm font-normal">{{ index + 1 }}</div>
                  </div>
                  <div class="text-gray-700 text-sm font-normal">{{ description.text }}</div>
                  <div class="justify-end items-right gap-8 flex ml-auto">
                    <div
                      class="flex items-center link-primary no-underline ml-4"
                      @click="copyText(description)"
                    >
                      <component
                        :is="description.icon"
                        class="mr-2 grayColor"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex h-full items-center justify-center"
      >
        <brightbid-loader size="120" />
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/base/page/Page.vue'
import SearchInput from '@/components/input/brightbid/SearchInput.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import NumericInput from '@/components/input/base/NumericInput.vue'
import IcCopy from 'vue-material-design-icons/ContentCopy'
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import IcStars from '@/components/icon/brightbid/ic-stars.vue'
import VueTagsInput from '@johmun/vue-tags-input'
import IcCheck from 'vue-material-design-icons/Check'
import { mapActions, mapGetters, mapState } from 'vuex'
import Toast from '@/components/shared/Toast.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader'

export default {
  name: 'AdGenerator',
  components: {
    Page,
    PageSection,
    SearchInput,
    NumericInput,

    MergeButtonRound,
    IcStars,
    VueTagsInput,
    IcCheck,
    IcCopy,
    BrightbidLoader,
  },

  data() {
    return {
      word: '',
      words: [],
      isAdLoading: false,
      isPageLoading: false,
      requestBody: {
        offering_id: null,
        headlines: 5,
        descriptions: 5,
        keywords: [],
        user_email: '',
      },
      result: {
        headlines: [],
        descriptions: [],
      },
      siteLanguage: {},
    }
  },
  computed: {
    ...mapState('site', ['selectedSite', 'siteDetails']),
    ...mapGetters('offerings', ['offerings']),
    ...mapGetters('language', ['allLanguages']),
    ...mapGetters({
      user: 'auth/user',
    }),
    offeringOptions() {
      return this.offerings.map(offering => ({
        label: offering.name,
        value: offering.search_customer_product_id,
      }))
    },
    resultExist() {
      return this.result.headlines.length > 0 || this.result.descriptions.length > 0
    },
  },
  async mounted() {
    this.isPageLoading = true
    await this.fetchOfferings(this.selectedSite.value)
    await this.fetchLanguages()
    await this.fetchSiteDetails(this.selectedSite.value)
    this.setSiteLanguage()
    this.isPageLoading = false
  },
  methods: {
    ...mapActions('offerings', ['fetchOfferings']),
    ...mapActions('language', ['fetchLanguages']),
    ...mapActions('site', ['fetchSiteDetails']),

    selectOffering(offering) {
      this.requestBody.offering_id = offering.value
    },
    selectLanguage(language) {
      this.requestBody.language = language.label
    },
    setSiteLanguage() {
      this.siteLanguage = this.allLanguages?.find(language => language.value === this.siteDetails?.language_code)
      this.selectLanguage(this.siteLanguage)
    },
    async generate() {
      const container = document.getElementById('adGenerator')
      container.scrollTop = 0
      this.requestBody.keywords = this.words.map(item => item.text)
      this.requestBody.user_email = this.user.email
      this.isAdLoading = true
      try {
        const { data } = await this.$http.post(`/search/site/${this.selectedSite.value}/ads/generate`, this.requestBody)
        this.result.headlines = data.headlines.map(item => ({ icon: 'ic-copy', text: item }))
        this.result.descriptions = data.ad_descriptions.map(item => ({ icon: 'ic-copy', text: item }))
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: `Failed to load ads headlines and suggestions`,
            type: 'error',
          },
        })
      } finally {
        this.isAdLoading = false
      }
    },

    previous() {
      console.log('previous')
    },
    copyText(item) {
      navigator.clipboard.writeText(item.text)
      item.icon = 'ic-check'
      setTimeout(() => (item.icon = 'ic-copy'), 3000)
    },
  },
}
</script>

<style scoped>
.vue-tags-input {
  width: 500px;
  height: 100px;
  padding: 8px;
  background: white;
  border-radius: 8px;
  border: 1px #e5e7eb solid;
}
::v-deep .ti-input {
  border: 0px;
}
::v-deep .vue-tags-input {
  max-width: 500px;
}
::v-deep .ti-tag {
  background: #f9fafb;
  border-radius: 6px;
  border: 1px #eaeaff solid;
  color: #3e4552;
  font-size: 14px;
  padding: 3px;
}

.h-100 {
  height: 100vh;
}
.resultSection {
  background: rgba(249, 248, 255, 0.5);
  padding: 40px 49px;
}
.robotIcon {
  width: 52px;
  height: 67.826px;
}

.headlineSection {
  max-height: 324px;
  overflow-y: auto;
}
.main-container {
  scroll-behavior: smooth;
  overflow-y: auto;
  height: calc(100vh - 178px);
}
.headlineSection::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f0f1f3;
}
.grayColor {
  color: #6d7883;
}
</style>
