<template>
  <div class="numeric-input rounded-md border border-gray-200 items-center">
    <input
      v-model="value"
      type="number"
      class="form-control"
      :disabled="isDisabled"
      @wheel="onWheel"
    />
    <div class="controls">
      <button @click="increment">
        <ic-chevron
          :size="20"
          :direction="'up'"
        />
      </button>
      <button @click="decrement">
        <ic-chevron
          :size="20"
          :direction="'down'"
        />
      </button>
    </div>
  </div>
</template>

<script>
import IcChevron from '@/components/icon/ic-chevron'

export default {
  name: 'NumericInput',
  components: {
    icChevron: IcChevron,
  },
  props: {
    min: {
      type: Number,
      default: -Infinity,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      value: this.min ? this.min : 0,
    }
  },
  methods: {
    increment() {
      if (this.value < this.max) {
        this.value++
        this.$emit('input', this.value)
      }
    },
    decrement() {
      if (this.value > this.min) {
        this.value--
        this.$emit('input', this.value)
      }
    },
    onWheel(event) {
      if (event.deltaY < 0) {
        this.increment()
      } else if (event.deltaY > 0) {
        this.decrement()
      }
    },
  },
}
</script>

<style scoped>
*:focus {
  outline: none;
}
.numeric-input {
  display: flex;
  align-items: center;
  position: relative;
  width: 80px;
  height: 40px;
}

.numeric-input input {
  -moz-appearance: textfield;
  text-align: center;
  width: 50px;
}

.numeric-input input::-webkit-outer-spin-button,
.numeric-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.controls {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  border-top-right-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  background: #f9fafb;
  padding-left: 7px;
  padding-right: 7px;
}

.controls button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 1;
  padding: 0;
  height: 17px;
}

.controls button:first-child {
  margin-bottom: 2px;
}

input:disabled {
  background: none;
  border: none;
}
</style>
